<template>
  <div class="tabs">
    <div class="tab-header">
      <button
        @click="redirectHome"
        :class="{ backButton: true, active: activeTab }"
      >
        <i class="fa fa-chevron-left" />
        Back to Scoreboard
      </button>
      <div
        v-for="(tab, index) in tabs"
        :key="index"
        :class="{ active: activeTab === index }"
        @click="selectTab(index)"
      >
        {{ tab.title }}
      </div>
    </div>
    <div
      class="tab-indicator"
      :style="indicatorStyle"
    />
    <div class="tab-content">
      <div
        v-for="(tab, index) in tabs"
        :key="index"
        :class="{ active: activeTab === index }"
      >
        <h2 class="heading">
          {{ tab.heading }}
        </h2>
        <component
          :is="tab.component"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

import State from '../components/State.vue'
import AverageTeamBrain from '../components/AverageTeamBrain.vue'
import TeamBrainICP from '../components/TeamBrainICP.vue'
import TeamBrainVariation from '../components/TeamBrainVariation.vue'
import TeamSelfAwareness from '../components/TeamSelfAwareness.vue'
import QualityMetricsCCF from '../components/QualityMetricsCCF.vue'
import QualityMetricsIC from '../components/QualityMetricsIC.vue'
import PerformanceUnderPressure from '../components/PerformanceUnderPressure.vue'
import IndividualStrAndWeak from '../components/IndividualStrAndWeak.vue'
import TeamAverageAbilityCapacity from '../components/TeamAverageAbilityCapacity.vue'

export default {
  name: 'Report',
  components: {
    State,
    AverageTeamBrain,
    TeamBrainICP,
    TeamBrainVariation,
    TeamSelfAwareness,
    QualityMetricsCCF,
    QualityMetricsIC,
    PerformanceUnderPressure,
    IndividualStrAndWeak,
    TeamAverageAbilityCapacity
  },
  props: {
    campaignId: {
      type: String,
      required: true
    },
    otp: {
      type: String,
      required: false,
      default: null
    }
  },
  data () {
    return {
      activeTab: 0,
      tabs: [
        {
          title: 'State',
          heading: 'State',
          component: 'State'
        },
        {
          title: 'Average Team Brain',
          heading: 'Average Team Brain',
          component: 'AverageTeamBrain'
        },
        {
          title: 'Team Brain - Individual Cognitive Profiles',
          heading: 'Team Brain - Individual Cognitive Profiles',
          component: 'TeamBrainICP'
        },
        {
          title: 'Team Brain - Variation',
          heading: 'Team Brain - Variation',
          component: 'TeamBrainVariation'
        },
        {
          title: 'Team Average Ability / Capacity',
          heading: 'Team Average Ability / Capacity',
          component: 'TeamAverageAbilityCapacity'
        },
        {
          title: 'Individual Strength(s) and Challenge(s)',
          heading: 'Individual Strength(s) and Challenge(s)',
          component: 'IndividualStrAndWeak'
        },
        {
          title: 'Quality Metrics - Creativity & Cognitive Flexibility',
          heading: 'Quality Metrics - Creativity & Cognitive Flexibility',
          component: 'QualityMetricsCCF'
        },
        {
          title: 'Quality Metrics - Impulse Control',
          heading: 'Quality Metrics - Impulse Control',
          component: 'QualityMetricsIC'
        },
        {
          title: 'Performance Under Pressure',
          heading: 'Performance Under Pressure',
          component: 'PerformanceUnderPressure'
        },
        {
          title: 'Self-Awareness',
          heading: 'Self-Awareness',
          component: 'TeamSelfAwareness'
        }
      ]
    }
  },
  computed: {
    ...mapGetters({
      teamMembers: 'TEAMREPORTAUTO/getTeamMembers',
      teamMembersSummarized: 'TEAMREPORTAUTO/getTeamMembersSummarized',
      measureScores: 'TEAMREPORTAUTO/getMeasureScores',
      teamMeasureScores: 'TEAMREPORTAUTO/getMeasureScores',
      loadedData: 'TEAMREPORTAUTO/getLoadedData'
    }),
    indicatorStyle () {
      return {
        left: `${this.activeTab * 25}%`
      }
    }
  },
  methods: {
    selectTab (index) {
      this.activeTab = index
    },
    redirectHome () {
      window.history.back()
    },
    async fetchTeamReportData () {
      await this.$store.dispatch('TEAMREPORTAUTO/getResults', { otp: this.otp, campaignId: this.campaignId })
    }
  },
  async created () {
    await this.fetchTeamReportData()
    this.$store.dispatch('TEAMREPORTAUTO/setLoadedData', true)
  },
  destroyed () {
    this.$store.dispatch('TEAMREPORTAUTO/empty')
  }
}
</script>

<style scoped>
@import '../report.scss';
</style>
