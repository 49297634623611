<template>
  <div>
    <p>Performance under pressure is a summary of the teams capacity in each ability both easier and harder conditions.</p>
    <div class="bar-chart-container">
      <BarChart
        :chart-data="chartData"
        :options="chartOptions"
      />
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

import { BarChart } from 'vue-chart-3'
import {
  Chart as ChartJS,
  Title,
  Tooltip,
  Legend,
  BarElement,
  CategoryScale,
  LinearScale
} from 'chart.js'

import { attentionColor, workingMemoryColor, impulseControlColor, strategicThinkingColor, creativityCognitiveFlexibilityColor } from '../../AppReport/chartConfig'

ChartJS.register(
  Title,
  Tooltip,
  Legend,
  BarElement,
  CategoryScale,
  LinearScale
)

export default {
  name: 'PerformanceUnderPressure',
  components: {
    BarChart
  },
  data () {
    return {
      backgroundColors: [
        attentionColor,
        attentionColor,
        workingMemoryColor,
        workingMemoryColor,
        impulseControlColor,
        impulseControlColor,
        creativityCognitiveFlexibilityColor,
        creativityCognitiveFlexibilityColor,
        strategicThinkingColor,
        strategicThinkingColor
      ],
      measureIndices: {
        simple_attention: 0,
        attention_control: 1,
        short_term_memory: 2,
        working_memory: 3,
        impulse_control_easy: 4,
        impulse_control_hard: 5,
        creativity_cognitive_flexibility_easy: 6,
        creativity_cognitive_flexibility_hard: 7,
        strategic_thinking_easy: 8,
        strategic_thinking_hard: 9
      },
      chartData: {
        labels: [
          'Attention (Easy)',
          'Attention (Hard)',
          'Memory (Easy)',
          'Memory (Hard)',
          'Impulse (Easy)',
          'Impulse (Hard)',
          'Creativity (Easy)',
          'Creativity (Hard)',
          'Strategy (Easy)',
          'Strategy (Hard)'
        ],
        datasets: []
      },
      chartOptions: {
        responsive: true,
        scales: {
          y: {
            beginAtZero: true,
            max: 9
          }
        },
        plugins: {
          legend: {
            display: false
          },
          title: {
            display: false,
            text: 'Performance Under Pressure'
          },
          tooltip: {
            callbacks: {
              label: (ctx) => {
                return `${ctx.dataset.label}: ${Number(ctx.parsed.y.toFixed(1))}`
              }
            }
          }
        }
      }
    }
  },
  computed: {
    ...mapGetters({
      teamMembers: 'TEAMREPORTAUTO/getTeamMembers',
      teamMembersSummarized: 'TEAMREPORTAUTO/getTeamMembersSummarized',
      measureScores: 'TEAMREPORTAUTO/getMeasureScores',
      teamMeasureScores: 'TEAMREPORTAUTO/getTeamMeasureScores',
      loadedData: 'TEAMREPORTAUTO/getLoadedData'
    })
  },
  watch: {
    loadedData () {
      this.createChartDatasets()
    }
  },
  methods: {
    createChartDatasets () {
      const memberScores = this.teamMeasureScores.mean
      const results = []

      memberScores.forEach(result => {
        const measure = result.measure

        if (measure in this.measureIndices) {
          const index = this.measureIndices[measure]
          const stanine = result.stanine
          results[index] = stanine
        }
      })

      this.chartData.datasets.push({
        label: 'Score',
        data: results,
        backgroundColor: this.backgroundColors,
        borderColor: this.backgroundColors,
        borderWidth: 1
      })
    }
  }
}
</script>

<style scoped>
.bar-chart-container {
position: relative;
height: 400px;
width: 850px;
margin: 15px auto 0 auto;
}
</style>
