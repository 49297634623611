<template>
  <div>
    <p>The individual strenght(s) and challenge(s) indicates the team members individual strenght(s) and potential challenge(s). the basic comparison is “soft”, without scores.</p>
    <div
      class="table-container"
      v-if="loadedData"
    >
      <table>
        <thead>
          <tr>
            <th />
            <th
              v-for="(teamMember, index) in teamMembersSummarized"
              :key="index"
            >
              {{ teamMember.name }}
            </th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="(description, index) in descriptions"
            :key="index"
          >
            <td :style="{'min-width': '250px'}">
              {{ description }}
            </td>
            <td
              v-for="(teamMember, memberIndex) in teamMembersSummarized"
              :key="memberIndex"
              :style="getColor(teamMember, index)"
            />
          </tr>
        </tbody>
      </table>
    </div>
    <div class="labels-container">
      <span class="strength-label">1st strength</span>
      <span class="challenge-label">Challenge</span>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

import { Charts } from '@/content/reportContent.json'

export default {
  name: 'IndividualStrengthsAndWeaknessesTable',
  data () {
    return {
      mainMeasuresOrdered: {},
      strengthColor: '#00AE46',
      challengeColor: '#CD2E00',
      neutralColor: '#ffffff',
      descriptions: [
        'Simple Attention',
        'Attention Control',
        'Short-Term Memory',
        'Working Memory',
        'Impulse Control',
        'Creativity & Cognitive Flexibility',
        'Conceptualization',
        'Strategic Thinking'
      ]
    }
  },
  methods: {
    getMainMeasuresOrdered () {
      for (let i = 0; i < this.teamMembers.length; i++) {
        const id = this.teamMembers[i]
        const memberScores = this.measureScores[id]
        const mainMeasures = []

        memberScores.forEach(result => {
          const mainMeasure = Charts.MainMeasures[result.measure]

          if (mainMeasure) {
            mainMeasures[mainMeasure.order] = result
          }
        })
        this.mainMeasuresOrdered[id] = mainMeasures
      }
    },
    getColor (teamMember, index) {
      const id = teamMember.user_id
      const results = this.mainMeasuresOrdered[id]
      const result = results[index]

      if (result.is_candidate_max) {
        return { backgroundColor: this.strengthColor }
      } else if (result.is_candidate_min) {
        return { backgroundColor: this.challengeColor }
      }

      return this.neutralColor
    }
  },
  computed: {
    ...mapGetters({
      teamMembers: 'TEAMREPORTAUTO/getTeamMembers',
      teamMembersSummarized: 'TEAMREPORTAUTO/getTeamMembersSummarized',
      measureScores: 'TEAMREPORTAUTO/getMeasureScores',
      teamMeasureScores: 'TEAMREPORTAUTO/getTeamMeasureScores',
      loadedData: 'TEAMREPORTAUTO/getLoadedData'
    })
  },
  watch: {
    loadedData () {
      this.getMainMeasuresOrdered()
    }
  }
}
</script>

<style scoped>
.table-container {
  width: 100%;
  margin: 20px auto;
  overflow-x: auto;
  display: flex;
  justify-content: center;
}

table {
  border-collapse: collapse;
}

th, td {
  padding: 10px;
  text-align: center;
  border: 3px solid #e7e7e7be;
  font-size: 12px;
  width: 240px;
}

th {
  background-color: #293340;
  color: #FFFFFF;
}

.labels-container {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

.strength-label, .challenge-label {
  margin: 0 10px;
  padding: 4px 8px;
  color: #ffffff;
  border: 1px solid #ddd;
}

.strength-label {
  background-color: #00AE46;
  font-size: 12px;
}

.challenge-label {
  background-color: #CD2E00;
  font-size: 12px;
}
</style>
