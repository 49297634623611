<template>
  <div>
    <p>The test aims to reflect the capacity and cognitive base-line during optimal circumstances. Lower indicated state may affect the test result which should be considered when analysing the profile.</p>
    <div class="team-table">
      <table>
        <thead>
          <tr>
            <th>Team Member</th>
            <th>Overall State</th>
            <th>Environment</th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="member in teamMembersSummarized"
            :key="member.name"
          >
            <td>{{ member.name }}</td>
            <td>
              <span :class="['state-dot', stateColors[member.state]]" />
            </td>
            <td>{{ member.undisturbed_environment ? 'Undisturbed' : 'Disturbed' }}</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'State',
  data () {
    return {
      stateColors: {
        1: 'red',
        2: 'orange',
        3: 'green'
      }
    }
  },
  computed: {
    ...mapGetters({
      teamMembers: 'TEAMREPORTAUTO/getTeamMembers',
      teamMembersSummarized: 'TEAMREPORTAUTO/getTeamMembersSummarized',
      measureScores: 'TEAMREPORTAUTO/getMeasureScores',
      teamMeasureScores: 'TEAMREPORTAUTO/getMeasureScores',
      loadedData: 'TEAMREPORTAUTO/getLoadedData'
    })
  }
}
</script>

<style scoped>
.team-table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.team-table th,
.team-table td {
  padding: 4px 15px;
  text-align: center;
  font-size: 16px;
}

.team-table td {
  border: 1px solid #ddd;
}

.team-table th {
  background-color: #293340 ;
  color: #ffffff;
  font-size: 16px;
}

.state-dot {
  display: inline-block;
  width: 15px;
  height: 15px;
  border-radius: 50%;
}

.state-dot.green {
  background-color: green;
}

.state-dot.red {
  background-color: red;
}

.state-dot.orange {
  background-color: orange;
}
</style>
