<template>
  <div>
    <p>The average ability / capacity helps you analyze and understand your team's average cognitive capacity in each ability. Based on this the team may understand over- and under capacity in relation to different tasks and situations.</p>
    <div class="mixed-chart-container">
      <BarChart
        :chart-data="chartData"
        :options="chartOptions"
        :width="chartWidth"
        :height="chartHeight"
      />
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

import { Charts } from '@/content/reportContent.json'

import { BarChart } from 'vue-chart-3'
import {
  Chart as ChartJS,
  Title,
  Tooltip,
  Legend,
  CategoryScale,
  LinearScale,
  BarElement,
  LineElement,
  PointElement,
  LineController,
  BarController
} from 'chart.js'

import { attentionColor, workingMemoryColor, impulseControlColor, strategicThinkingColor } from '../../AppReport/chartConfig'
import { normStyling } from '@/utils/chartUtils.js'

ChartJS.register(
  Title,
  Tooltip,
  Legend,
  CategoryScale,
  LinearScale,
  BarElement,
  LineElement,
  PointElement,
  LineController,
  BarController
)

export default {
  name: 'TeamAverageAbilityCapacity',
  components: {
    BarChart
  },
  data () {
    return {
      backgroundColors: [
        attentionColor,
        attentionColor,
        workingMemoryColor,
        workingMemoryColor,
        impulseControlColor,
        impulseControlColor,
        strategicThinkingColor,
        strategicThinkingColor
      ],
      chartWidth: 840,
      chartHeight: 450,
      chartData: {
        labels: ['Simple Attention', 'Attention Control', 'Short-Term Memory', 'Working Memory', 'Impulse Control', 'Creativity & Cognitive Flexibility', 'Conceptualization', 'Strategic Thinking'],
        datasets: [
          {
            label: 'Norm',
            type: 'line',
            data: [5, 5, 5, 5, 5, 5, 5, 5], // Example data for Norm
            borderColor: normStyling.borderColor,
            // borderColor: '#525252',
            borderWidth: 2,
            fill: false,
            order: 1
          }
        ]
      },
      chartOptions: {
        responsive: true,
        maintainAspectRatio: false,
        scales: {
          y: {
            beginAtZero: true,
            max: 9
          },
          x: {
            grid: {
              offset: true
            },
            offset: true
          }
        },
        plugins: {
          legend: {
            position: 'bottom',
            labels: {
              usePointStyle: true,
              pointStyle: 'line',
              filter: (legendItem, data) => {
                const label = data.datasets[legendItem.datasetIndex].label || ''
                if (typeof (label) !== 'undefined') {
                  if (legendItem.datasetIndex === 1) { // filter out the 'score' dataset
                    return false
                  }
                }
                return label
              },
              font: {
                size: 12
              }
            }
          },
          tooltip: {
            callbacks: {
              label: (ctx) => {
                return `${ctx.dataset.label}: ${Number(ctx.parsed.y.toFixed(1))}`
              }
            }
          }
        }
      }
    }
  },
  computed: {
    ...mapGetters({
      teamMembers: 'TEAMREPORTAUTO/getTeamMembers',
      teamMembersSummarized: 'TEAMREPORTAUTO/getTeamMembersSummarized',
      measureScores: 'TEAMREPORTAUTO/getMeasureScores',
      teamMeasureScores: 'TEAMREPORTAUTO/getTeamMeasureScores',
      loadedData: 'TEAMREPORTAUTO/getLoadedData'
    })
  },
  watch: {
    loadedData () {
      this.createChartDatasets()
    }
  },
  methods: {
    createChartDatasets () {
      const memberScores = this.teamMeasureScores.mean
      const mainMeasures = []

      memberScores.forEach(result => {
        const mainMeasure = Charts.MainMeasures[result.measure]

        if (mainMeasure) {
          mainMeasures[mainMeasure.order] = result.stanine
        }
      })

      const meanCapacity = mainMeasures.reduce((acc, val) => acc + val, 0) / mainMeasures.length

      this.chartData.datasets.push({
        label: 'Score',
        type: 'bar',
        data: mainMeasures,
        backgroundColor: this.backgroundColors,
        borderColor: this.backgroundColors,
        borderWidth: 1,
        order: 2
      })

      this.chartData.datasets.push({
        label: 'Mean',
        type: 'line',
        data: new Array(mainMeasures.length).fill(meanCapacity),
        borderColor: 'rgba(255, 255, 0, 1)',
        borderDash: [5, 5],
        borderWidth: 2,
        fill: false,
        order: 1
      })
    }
  }
}
</script>

<style scoped>
.mixed-chart-container {
position: relative;
height: 400px;
width: 800px;
margin: 15px auto 0 auto;
}
</style>
