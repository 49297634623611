<template>
  <div>
    <p>The average team brain shows the mean result of the individual profiles and indicates the common strengths and challenges of the team.</p>
    <div class="radar-chart-container">
      <RadarChart
        :chart-data="chartData"
        :options="chartOptions"
        :width="500"
        :height="500"
        v-if="loadedData"
      />
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

import { Charts } from '@/content/reportContent.json'

import { RadarChart } from 'vue-chart-3'
import {
  Chart as ChartJS,
  Title,
  Tooltip,
  Legend,
  RadialLinearScale,
  PointElement,
  LineElement,
  Filler
} from 'chart.js'

ChartJS.register(
  Title,
  Tooltip,
  Legend,
  RadialLinearScale,
  PointElement,
  LineElement,
  Filler
)

export default {
  name: 'AverageTeamBrain',
  components: {
    RadarChart
  },
  data () {
    return {
      chartData: {
        labels: ['Simple Attention', 'Attention Control', 'Short-Term Memory', 'Working Memory', 'Impulse Control', 'Creativity & Cognitive Flexibility', 'Conceptualization', 'Strategic Thinking'],
        datasets: []
      },
      chartOptions: {
        responsive: true,
        maintainAspectRatio: false,
        scales: {
          r: {
            angleLines: {
              display: true
            },
            suggestedMin: 0,
            suggestedMax: 9
          }
        },
        plugins: {
          legend: {
            position: 'bottom'
          },
          tooltip: {
            callbacks: {
              label: (ctx) => {
                return `${ctx.dataset.label}: ${Number(ctx.parsed.r.toFixed(1))}`
              }
            }
          }
        }
      }
    }
  },
  computed: {
    ...mapGetters({
      teamMembers: 'TEAMREPORTAUTO/getTeamMembers',
      teamMembersSummarized: 'TEAMREPORTAUTO/getTeamMembersSummarized',
      measureScores: 'TEAMREPORTAUTO/getMeasureScores',
      teamMeasureScores: 'TEAMREPORTAUTO/getTeamMeasureScores',
      loadedData: 'TEAMREPORTAUTO/getLoadedData'
    })
  },
  watch: {
    loadedData () {
      this.createChartDatasets()
    }
  },
  methods: {
    createChartDatasets () {
      const memberScores = this.teamMeasureScores.mean
      const mainMeasures = []

      memberScores.forEach(result => {
        const mainMeasure = Charts.MainMeasures[result.measure]

        if (mainMeasure) {
          mainMeasures[mainMeasure.order] = result.stanine
        }
      })
      this.chartData.datasets.push({
        label: 'Average Team Brain',
        backgroundColor: 'rgba(54, 162, 235, 0.2)',
        borderColor: 'rgba(54, 162, 235, 1)',
        pointBackgroundColor: 'rgba(54, 162, 235, 1)',
        data: mainMeasures
      })
    }
  }
}
</script>

<style scoped>
  .radar-chart-container {
    position: relative;
    height: auto;
    width: auto;
    margin: 15px auto 0 auto;
  }
</style>
