<template>
  <div>
    <p>
      The graph shows the balance and tendency between average speed and precision (rule focus and repetition avoidance) in the team, when solving new problems and switching between tasks.
    </p>
    <div class="bar-chart-container">
      <BarChart
        :chart-data="chartData"
        :options="chartOptions"
      />
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

import { creativityCognitiveFlexibilityColor } from '../../AppReport/chartConfig'
import { processSpeedColor, repetitionAvoidanceColor } from '../chartConfig'

import { BarChart } from 'vue-chart-3'
import {
  Chart as ChartJS,
  Title,
  Tooltip,
  Legend,
  BarElement,
  CategoryScale,
  LinearScale
} from 'chart.js'

ChartJS.register(
  Title,
  Tooltip,
  Legend,
  BarElement,
  CategoryScale,
  LinearScale
)

export default {
  name: 'QualityMetricsCCF',
  components: {
    BarChart
  },
  data () {
    return {
      backgroundColors: [
        creativityCognitiveFlexibilityColor,
        processSpeedColor,
        repetitionAvoidanceColor,
        repetitionAvoidanceColor
      ],
      measureIndices: {
        creativity_cognitive_flexibility: 0,
        process_speed: 1,
        repetition_avoidance: 2,
        rule_focus: 3
      },
      chartData: {
        labels: [
          'Creativity & Flexibility (Main)',
          'Process Speed',
          'Repetition Avoidance',
          'Rule Focus'
        ],
        datasets: []
      },
      chartOptions: {
        responsive: true,
        scales: {
          y: {
            beginAtZero: true,
            max: 9
          }
        },
        plugins: {
          legend: {
            display: false
          },
          title: {
            display: false,
            text: 'Quality Metrics - Creative & Cognitive Flexibility'
          },
          tooltip: {
            callbacks: {
              label: (ctx) => {
                return `${ctx.dataset.label}: ${Number(ctx.parsed.y.toFixed(1))}`
              }
            }
          }
        }
      }
    }
  },
  computed: {
    ...mapGetters({
      teamMembers: 'TEAMREPORTAUTO/getTeamMembers',
      teamMembersSummarized: 'TEAMREPORTAUTO/getTeamMembersSummarized',
      measureScores: 'TEAMREPORTAUTO/getMeasureScores',
      teamMeasureScores: 'TEAMREPORTAUTO/getTeamMeasureScores',
      loadedData: 'TEAMREPORTAUTO/getLoadedData'
    })
  },
  watch: {
    loadedData () {
      this.createChartDatasets()
    }
  },
  methods: {
    createChartDatasets () {
      const memberScores = this.teamMeasureScores.mean
      const results = []

      memberScores.forEach(result => {
        const measure = result.measure

        if (measure in this.measureIndices) {
          const index = this.measureIndices[measure]
          results[index] = result.stanine
        }
      })

      this.chartData.datasets.push({
        label: 'Main Score',
        data: results,
        backgroundColor: this.backgroundColors,
        borderColor: this.backgroundColors,
        borderWidth: 1
      })
    }
  }
}
</script>

<style scoped>
.bar-chart-container {
position: relative;
height: 400px;
width: 600px;
margin: 15px auto 0 auto;
}
</style>
