<template>
  <div>
    <p>The individual cognitive profiles indicate the cognitive abilities of each team member. The purpose of the graph is to show the cognitive diversity in the team, rather than comparing individuals. </p>
    <div class="radar-chart-container">
      <RadarChart
        :chart-data="chartData"
        :options="chartOptions"
        :width="500"
        :height="500"
      />
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

import { Charts } from '@/content/reportContent.json'

import { RadarChart } from 'vue-chart-3'
import {
  Chart as ChartJS,
  Title,
  Tooltip,
  Legend,
  RadialLinearScale,
  PointElement,
  LineElement,
  Filler
} from 'chart.js'

ChartJS.register(
  Title,
  Tooltip,
  Legend,
  RadialLinearScale,
  PointElement,
  LineElement,
  Filler
)

export default {
  name: 'TeamBrainICP',
  components: {
    RadarChart
  },
  data () {
    return {
      borderColors: ['#00AE46', '#007C32', '#2449A1', '#3267E3', '#B1660D', '#F98F12', '#922100', '#CD2E00', '#525252', '#D3C5B1'],
      chartData: {
        labels: ['Simple Attention', 'Attention Control', 'Short-Term Memory', 'Working Memory', 'Impulse Control', 'Creativity & Cognitive Flexibility', 'Conceptualization', 'Strategic Thinking'],
        datasets: []
      },
      chartOptions: {
        responsive: true,
        maintainAspectRatio: false,
        scales: {
          r: {
            angleLines: {
              display: true
            },
            suggestedMin: 0,
            suggestedMax: 9
          }
        },
        plugins: {
          legend: {
            position: 'bottom'
          },
          tooltip: {
            callbacks: {
              label: (ctx) => {
                return `${ctx.dataset.label}: ${Number(ctx.parsed.r.toFixed(1))}`
              }
            }
          }
        }
      }
    }
  },
  computed: {
    ...mapGetters({
      teamMembers: 'TEAMREPORTAUTO/getTeamMembers',
      teamMembersSummarized: 'TEAMREPORTAUTO/getTeamMembersSummarized',
      measureScores: 'TEAMREPORTAUTO/getMeasureScores',
      teamMeasureScores: 'TEAMREPORTAUTO/getTeamMeasureScores',
      loadedData: 'TEAMREPORTAUTO/getLoadedData'
    })
  },
  watch: {
    loadedData () {
      this.createChartDatasets()
    }
  },
  methods: {
    createChartDatasets () {
      for (let i = 0; i < this.teamMembers.length; i++) {
        const id = this.teamMembers[i]
        const member = this.teamMembersSummarized[id]
        const memberScores = this.measureScores[id]
        const mainMeasures = []

        memberScores.forEach(result => {
          const mainMeasure = Charts.MainMeasures[result.measure]

          if (mainMeasure) {
            mainMeasures[mainMeasure.order] = result.stanine
          }
        })
        this.chartData.datasets.push({
          label: member.name,
          backgroundColor: 'transparent',
          borderColor: this.borderColors[i],
          pointBackgroundColor: this.borderColors[i],
          data: mainMeasures
        })
      }
    }
  }
}
</script>

<style scoped>
    .radar-chart-container {
      position: relative;
      height: auto;
      width: auto;
      margin: 15px auto 0 auto;
    }
</style>
