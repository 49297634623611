<template>
  <div>
    <p>The chart shows the accuracy in self-regulation accuracy in relation to the main score that also incorporates the speed.</p>
    <div class="bar-chart-container">
      <BarChart
        :chart-data="chartData"
        :options="chartOptions"
      />
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

import { BarChart } from 'vue-chart-3'
import {
  Chart as ChartJS,
  Title,
  Tooltip,
  Legend,
  BarElement,
  CategoryScale,
  LinearScale
} from 'chart.js'

import { impulseControlColor } from '../../AppReport/chartConfig'
import { repetitionAvoidanceColor } from '../chartConfig'

ChartJS.register(
  Title,
  Tooltip,
  Legend,
  BarElement,
  CategoryScale,
  LinearScale
)

export default {
  name: 'QualityMetricsIC',
  components: {
    BarChart
  },
  data () {
    return {
      backgroundColors: [
        impulseControlColor,
        repetitionAvoidanceColor
      ],
      measureIndices: {
        impulse_control: 0,
        accuracy: 1
      },
      chartData: {
        labels: [
          'Impulse Control (Main)',
          'Accuracy'
        ],
        datasets: []
      },
      chartOptions: {
        responsive: true,
        scales: {
          y: {
            beginAtZero: true,
            max: 9
          }
        },
        plugins: {
          legend: {
            display: false
          },
          title: {
            display: false,
            text: 'Quality Metrics Impulse Control'
          },
          tooltip: {
            callbacks: {
              label: (ctx) => {
                return `${ctx.dataset.label}: ${Number(ctx.parsed.y.toFixed(1))}`
              }
            }
          }
        }
      }
    }
  },
  computed: {
    ...mapGetters({
      teamMembers: 'TEAMREPORTAUTO/getTeamMembers',
      teamMembersSummarized: 'TEAMREPORTAUTO/getTeamMembersSummarized',
      measureScores: 'TEAMREPORTAUTO/getMeasureScores',
      teamMeasureScores: 'TEAMREPORTAUTO/getTeamMeasureScores',
      loadedData: 'TEAMREPORTAUTO/getLoadedData'
    })
  },
  watch: {
    loadedData () {
      this.createChartDatasets()
    }
  },
  methods: {
    createChartDatasets () {
      const memberScores = this.teamMeasureScores.mean
      const results = []

      memberScores.forEach(result => {
        const measure = result.measure

        if (measure in this.measureIndices) {
          const index = this.measureIndices[measure]
          results[index] = result.stanine
        }
      })

      this.chartData.datasets.push({
        label: 'Main Score',
        data: results,
        backgroundColor: this.backgroundColors,
        borderColor: this.backgroundColors,
        borderWidth: 1
      })
    }
  }
}
</script>

<style scoped>
.bar-chart-container {
position: relative;
height: 400px;
width: 300px;
margin: 15px auto 0 auto;
}
</style>
